@import "@/assets/scss/style.scss";

.ant-row {
    padding: 5px 0;

    .ant-col {
        color: $grey-9;
        text-align: right;

        &:first-child {
            color: $grey-7;
            text-align: left;
        }
        &:last-child {
            color: $grey-7;
            font-size: 12px;
            margin-bottom: 3px;
        }
    }
}
