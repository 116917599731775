@import "@/assets/scss/style.scss";

.ant-card {
    width: 85%;
    border-radius: 20px;
    color: $grey-9;
    background-color: $grey-2;
    margin-bottom: 10px;

    &.right {
        margin-left: 15%;
    }

    .from {
        font-size: 12px;
        color: $grey-8;
        line-height: 16px;
        font-weight: 400;
        margin-top: 10px;
    }
}

.content {
    color: $grey-9;
    margin-bottom: 8px;

    &::v-deep {
        p {
            margin-bottom: 0;
        }

        a {
            text-decoration: underline;
        }
    }
}

.activity {
    padding-left: 36px;

    &:before {
        content: '';
        display: block;
        box-sizing: content-box;
        height: calc(100% - 32px);
        background-color: $blue-6;
        width: 4px;
        border-radius: 5px;
        position: absolute;
        top: 50%;
        left: 18px;
        transform: translateY(-50%);
    }
}
