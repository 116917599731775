@import "@/assets/scss/style.scss";

.ant-row {
    padding: 5px 0;

    .ant-col {
        &:first-child {
            color: $grey-7;
        }
        &:last-child {
            color: $grey-9;
            text-align: right;
        }

        .ant-select {
            width: 100%;
        }
    }
}
