@import "@/assets/scss/style.scss";

.hr {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-top: 5px;
    height: 10px;
    border-top: 1px solid $grey-4;
}
.backdrop {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}
.progress-dropdown {
    position: absolute;
    top: 55px;
    right: 20px;
    z-index: 100;
    width: 250px;
    border-radius: 10px;

    .list-item {
        color: $grey-9;
        padding: 5px 0;
        cursor: pointer;
        &:hover {
            background-color: $grey-2;
        }
    }

    .actions {
        .ant-btn {
            width: 100%;
        }
    }
}
