@import "@/assets/scss/style.scss";

.custom-order-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__message {
        margin: 0;
    }
}
