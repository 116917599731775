@import "@/assets/scss/style.scss";

.wrapperProjectContent {
    padding: 0 64px;

    @media (max-width: 650px) {
        padding: 0 20px;
    }

    @media (max-width: 450px) {
        padding: 0 10px;
    }

    &.isLoading {
        &::v-deep {
            .ant-tabs-content {
                opacity: 0.4;
            }
        }
    }

    &::v-deep {
        .ant-tabs-bar {
            border-bottom: none;
            margin-bottom: 32px;
        }

        .ant-spin {
            display: flex;
            position: absolute;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 50;
        }
    }
}
