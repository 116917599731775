@import "@/assets/scss/style.scss";

.misc {
    padding: 0 8px;
}
.wrapperProjectTopBar {
    display: flex;
    align-items: center;
    width: auto;
    align-items: center;
    justify-content: space-between;
    min-height: 5rem;
    height: 100%;
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 0 5%;

    @media (max-width: 512px) {
        padding: 3%;
        gap: 1rem;
        flex-direction: column;
    }

    .path-before {
        margin-left: 10px;
        font-size: 0.96rem;
        font-weight: 400;
        color: $grey-7;
    }
}

.left-side-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .product-info {
        padding: 0 1.5rem;

        .path-current {
            word-break: break-all;
            font-size: 0.96rem;
            font-weight: 450;
            color: #262626;
        }

        .product-name {
            font-size: 1.125rem;
            font-weight: bold;
            color: $grey-9;
        }
    }
}

.right-side-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}
