@import "@/assets/scss/style.scss";

.misc {
    padding: 0 8px !important;
}
.wrapperProjectActionButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
        margin-right: 8px;
        padding: 6px;
    }
}
