@import "@/assets/scss/style.scss";

a {
    text-decoration: underline;
    color: $mid-blue;
}

.stateId {
    margin-right: 6px;
    padding-right: 6px;

    &.has-separator {
        border-right: 1px solid $cool-grey;
    }
}

.wrapperProjectDynamicInfoBar {
    margin-left: 8px;
    color: $charcoal-grey;

    .path-current {
        word-break: break-all;
        font-size: 0.96rem;
        font-weight: 550;
        color: #262626;
        text-decoration: none !important;
    }
}
