@import "@/assets/scss/style.scss";

.activity-message-wrapper {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 8px;
    max-width: 80%;
    justify-content: space-between;

    &.full-width {
        max-width: 100%;

        .cell {
            flex: 1 0 100%;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.cell {
    display: flex;
    flex-direction: column;
    flex: 1 0 50%;
}

a {
    color: $blue-6;
    margin-top: 2px;
}

.activity-label {
    @extend .u-b2--;
    color: $grey-7;
    margin-bottom: 2px;
}

.value {
    @extend .u-b1;
    color: $grey-9;

    .user {
        color: $blue-6;
        background-color: $blue-1;
        border-radius: 4px;
    }
}
